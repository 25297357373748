import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Text, ValidatingInput } from '@it25syv/25syv-ui';

import UserConductor from 'common/conductors/UserConductor';
import NotificationConductor from 'common/conductors/NotificationConductor';
import UserProfile from 'features/UserArea/UserProfile/UserProfile';
import Profile from 'features/UserArea/UserProfile/Profile';

import getTexts from 'utils/localization';
import { isMobile } from 'utils/platforms';
const t = getTexts();

class UserProfileContainer extends Component {
    userProfileFormValidator = null;
    userProfileFormGrabber = null;

    constructor() {
        super();
        this.state = {
            profile: {},
            loading: true,
            currentPasswordIncorrect: false,
        };
    }

    componentDidMount() {
        isMobile && this.props.enableCloseButton();

        UserConductor.getProfile()
            .then(profile => {
                this.setState({ profile, loading: false });
            })
            .catch(err => NotificationConductor.error(err.toString()));
    }

    setProfileFormValidator = handler => {
        this.userProfileFormValidator = handler;
    };

    setProfileFormGrabber = handler => {
        this.userProfileFormGrabber = handler;
    };

    submitProfile = () => {
        if (!this.userProfileFormValidator()) {
            return false;
        }

        const profile = this.userProfileFormGrabber();
        UserConductor.updateProfile(Profile.unserialize(profile))
            .then(() => NotificationConductor.success(t.userArea.profile.profileSuccess))
            .catch(err => NotificationConductor.error(err.toString()));
    };

    submitPassword = (currentPassword, newPassword, passwordConfirm) => {
        UserConductor.updatePassword(currentPassword, newPassword, passwordConfirm)
            .then(response => {
                if (response.success) {
                    NotificationConductor.success(t.userArea.profile.changePwdSuccess);
                    return this.setState({ currentPasswordIncorrect: false });
                }
                if (response.errorMessage === 'PASSWOLDINCORRECT') {
                    return this.setState({ currentPasswordIncorrect: true });
                }

                NotificationConductor.error(response.errorMessage);
            })
            .catch(err => NotificationConductor.error(err.toString()));
    };

    render() {
        const { profile, loading } = this.state;

        return (
            <UserProfile
                profile={profile}
                loading={loading}
                setProfileFormValidator={this.setProfileFormValidator}
                setProfileFormGrabber={this.setProfileFormGrabber}
                submitProfile={this.submitProfile}
                submitPassword={this.submitPassword}
                desktop={this.props.desktop}
                currentPasswordIncorrect={this.state.currentPasswordIncorrect}
            />
        );
    }
}

export default withRouter(UserProfileContainer);
