import React from 'react';
import styled from 'styled-components';
import Button from 'ui/controls/Button';
import Text from 'ui/Text';
import { COLORS } from 'themes';
import { InputControl, InputSubLabel, InputWrapper } from 'ui/controls/Input';

const fullWidthBorderRadius = `15px`;

const Body = styled.main`
    padding: 0 40px 40px 40px;
`;

const FullWidthButton = styled(Button)`
    width: 100%;
    border-radius: ${fullWidthBorderRadius};
    margin: 0;
    &.top-indent {
        margin-top: 20px;
    }
`;

const StyledText = styled(Text)`
    padding-left: ${fullWidthBorderRadius};
`;

const SmallButton = styled(Button)`
    border-radius: ${fullWidthBorderRadius};
    padding: 10px 20px;
`;

const ChangePassword = styled.section`
    padding-top: 20px;
`;

const SmallerBorderRadiusInputWrapper = styled(InputWrapper)`
    border-radius: ${fullWidthBorderRadius};
    margin-left: 0;
    margin-right: 0;
`;

const ChatMark = styled.mark`
    display: block;
    margin: ${fullWidthBorderRadius};
    font-style: italic;
    background: none;
    font-size: 14px;
    color: ${COLORS.primaryText};
`;

const SmallerBorderRadiusInput = props => {
    return (
        <SmallerBorderRadiusInputWrapper>
            <InputControl {...props} />
            {props.validationError && (
                <InputSubLabel color="red">{props.validationError}</InputSubLabel>
            )}
        </SmallerBorderRadiusInputWrapper>
    );
};

const ProfileCard = styled.div`
    border-radius: 5px;
    border: 1px solid ${COLORS.lightGrey};
    color: ${COLORS.mutedText};
    background: #f5f8fc;
    font-size: 14px;
    padding: 15px 20px;
    font-weight: 700;
`;

const SaveButtonBlock = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: 1px solid #eceff4;
    padding: 20px 15px 30px 15px;
    box-sizing: border-box;
`;

export {
    Body,
    ChangePassword,
    ChatMark,
    StyledText as Text,
    FullWidthButton,
    SmallButton,
    SmallerBorderRadiusInput,
    ProfileCard,
    SaveButtonBlock,
};
