import React, { Component, Fragment } from 'react';
import { ContentHeight, Fillable, FullScreen } from 'ui/FullScreen';
import TopBar from 'ui/topbar';
import TopBarText from 'ui/topbar/Text';
import {
    Body,
    ChangePassword,
    ChatMark,
    FullWidthButton,
    SmallButton,
    SmallerBorderRadiusInput,
    Text,
} from 'features/UserArea/UserProfile/styled';
import Spinner from 'ui/Spinner';
import UserProfileForm from 'features/UserArea/UserProfile/UserProfileForm';
import { SUPPORT_EMAIL } from 'configs/main';

import getTexts from 'utils/localization';
const t = getTexts();

class UserProfile extends Component {
    constructor() {
        super();
        this.state = {
            currentPassword: '',
            newPassword: '',
            passwordConfirm: '',
            formTouched: false,
        };
    }

    setFormTouched = () => {
        !this.state.formTouched && this.setState({ formTouched: true });
    };

    onCurrentPasswordChange = e => {
        this.setState({ currentPassword: e.target.value });
    };

    onNewPasswordChange = e => {
        this.setState({ newPassword: e.target.value });
    };

    onPasswordConfirmChange = e => {
        this.setState({ passwordConfirm: e.target.value });
    };

    submitPassword = () => {
        const { currentPassword, newPassword, passwordConfirm } = this.state;
        this.props.submitPassword(currentPassword, newPassword, passwordConfirm);
    };

    passwordsMatch = () => {
        const { newPassword, passwordConfirm } = this.state;

        return newPassword === passwordConfirm;
    };

    passwordValid = () => {
        const { newPassword } = this.state;

        return newPassword.match(/^(?=.{10,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*\d+.*\d+).*$/g);
    };

    startChat = () => {
        window.Intercom('show');
    };

    render() {
        const {
            profile,
            loading,
            setProfileFormValidator,
            setProfileFormGrabber,
            submitProfile,
            currentPasswordIncorrect,
        } = this.props;

        const { currentPassword, newPassword, passwordConfirm } = this.state;

        return (
            <FullScreen>
                {!this.props.desktop ? (
                    <ContentHeight>
                        <TopBar>
                            <TopBarText className="sm">{t.userArea.profile.title}</TopBarText>
                        </TopBar>
                    </ContentHeight>
                ) : null}
                <Fillable>
                    <Body>
                        {loading ? (
                            <Spinner />
                        ) : (
                            <Fragment>
                                <Text black style={{ marginTop: '20px' }}>
                                    {t.userProfile.changeAddress}{' '}
                                    <a
                                        href={`mailto:${SUPPORT_EMAIL}`}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        {SUPPORT_EMAIL}
                                    </a>
                                </Text>
                                <UserProfileForm
                                    defaultData={profile}
                                    setFormValidationHandler={setProfileFormValidator}
                                    dataCallback={setProfileFormGrabber}
                                    readOnlyFields={true}
                                />
                                <FullWidthButton
                                    className="top-indent"
                                    size="x2"
                                    onClick={submitProfile}
                                >
                                    {t.userArea.profile.updateProfileSubmit}
                                </FullWidthButton>
                                <ChangePassword>
                                    <Text bold black>
                                        {t.userArea.profile.changePwdExplanation}
                                    </Text>
                                    <SmallerBorderRadiusInput
                                        placeholder={t.userArea.profile.currentPwdPlaceholder}
                                        value={currentPassword}
                                        onChange={this.onCurrentPasswordChange}
                                        onFocus={this.setFormTouched}
                                        type="password"
                                        validationError={
                                            (this.state.formTouched &&
                                                !this.state.currentPassword) ||
                                            currentPasswordIncorrect
                                                ? t.userArea.profile.pwdErrors.PASSWOLDINCORRECT
                                                : null
                                        }
                                    />
                                    <SmallerBorderRadiusInput
                                        placeholder={t.userArea.profile.newPwd}
                                        value={newPassword}
                                        onChange={this.onNewPasswordChange}
                                        onFocus={this.setFormTouched}
                                        type="password"
                                        validationError={
                                            newPassword && !this.passwordValid()
                                                ? t.userArea.profile.pwdErrors.VALIDATION
                                                : null
                                        }
                                    />
                                    <SmallerBorderRadiusInput
                                        placeholder={t.userArea.profile.pwdConfirm}
                                        value={passwordConfirm}
                                        onChange={this.onPasswordConfirmChange}
                                        onFocus={this.setFormTouched}
                                        type="password"
                                        validationError={
                                            !this.passwordsMatch()
                                                ? t.userArea.profile.pwdConfirmError
                                                : null
                                        }
                                    />
                                    <FullWidthButton
                                        size="x2"
                                        onClick={this.submitPassword}
                                        disabled={
                                            !this.state.currentPassword ||
                                            !this.passwordsMatch() ||
                                            !this.passwordValid()
                                        }
                                    >
                                        {t.userArea.profile.pwdSubmit}
                                    </FullWidthButton>
                                </ChangePassword>
                                <ChatMark blue>{t.userArea.profile.pwdChatMark}</ChatMark>
                                <SmallButton onClick={this.startChat}>
                                    {t.userArea.profile.pwdStartChat}
                                </SmallButton>
                            </Fragment>
                        )}
                    </Body>
                </Fillable>
            </FullScreen>
        );
    }
}

export default UserProfile;
