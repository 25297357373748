import React from 'react';
import GlobalStyleTheme from './dk/global-inject';
import { createGlobalStyle } from 'styled-components';
import * as utils from './utils';

const GlobalCSSUtils = createGlobalStyle`
        body {
            left: 0;
            top: 0;
            right: 0;
        }
        ${utils.fullWidth()}
        ${utils.fullHeight()}
        ${utils.uppercase()}
        ${utils.lowercase()}
        ${utils.capitalize()}
        ${utils.boxSizing()}
        ${utils.flex()}
        ${utils.textCenter()}
        ${utils.textLeft()}
        ${utils.textRight()}
        ${utils.resetLink()}
        ${utils.blockCenter()}
        ${utils.verticalSpaceBetween()}
        ${utils.withoutScroll()}
        ${utils.shortText()}
        ${utils.supportLinebreaks()}
        ${utils.breakWords()}
        ${utils.textBold()}
        ${utils.textItalic()}
        ${utils.textUnderline()}
        ${utils.pointer()}
        ${utils.fadeIn()}
        ${utils.fadeOut()}
        ${utils.gaps()}
        ${utils.paddings()}
        ${utils.grid()}
    `;

const GlobalStyles = () => {
    return (
        <div>
            <GlobalStyleTheme />
            <GlobalCSSUtils />
        </div>
    );
};

export default GlobalStyles;
