import React from 'react';
import styled from 'styled-components';
import { UserHistory } from '@it25syv/25syv-ui';

import { hidePopup, showPopup } from 'utils/navigation';
import { byPlatform } from 'utils/platforms';
import getTexts from 'utils/localization';

import Modal from 'ui/ModalContent';

import useTransactionQuery from './hooks/useTransactionsQuery';
import useTransactionStateStore from './hooks/useTransactionStateStore';
import NewTopBar from '../NewTopBar';

const ModalWrapper = styled(Modal)`
    display: flex;
    flex-direction: column;
`;

const t = getTexts();

const TransactionsHistory = () => {
    const { transactionState, setTransactionState } = useTransactionStateStore();

    const { dateFrom, dateTo } = transactionState ?? {};

    const { transactions, hasNextPage, fetchNextPage, isLoading } = useTransactionQuery();

    const loadMore = () => {
        if (hasNextPage) {
            fetchNextPage();
        }
    };

    const renderContent = () => {
        return (
            <UserHistory
                showDetails={data => {
                    showPopup('TRANSACTION_INFO', {
                        ...data,
                        keepPreviousBackgroundLocation: true,
                    });
                }}
                transactions={transactions ?? []}
                setTab={selectedTab => {
                    setTransactionState({ group: selectedTab.groupKey });
                }}
                setInitialConfig={config => {
                    const { dateFrom, dateTo, rowCount = 20 } = config;
                    setTransactionState({ rowCount, dateFrom, dateTo });
                }}
                loading={isLoading}
                loadMoreHandler={loadMore}
                loadMoreColor={'#1CA963'}
                showMore={hasNextPage}
                openChooseDate={() => {
                    showPopup('CHOOSE_DATE_POPUP', { keepPreviousBackgroundLocation: true });
                }}
                currentDate={{ dateFrom, dateTo }}
                resetDateHandler={date => {
                    setTransactionState({ ...date });
                }}
            />
        );
    };

    return byPlatform(
        <ModalWrapper>
            <NewTopBar
                goBack={() => {
                    hidePopup('TRANSACTIONS');
                }}
                close={() => {
                    hidePopup('TRANSACTIONS');
                    hidePopup('USER_AREA_MOBILE');
                }}
            >
                {t.userArea.nav.transactions}
            </NewTopBar>
            {renderContent()}
        </ModalWrapper>,
        renderContent()
    );
};

export default TransactionsHistory;
