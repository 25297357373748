import Deposit from 'features/UserArea/Deposit';
import DepositLimits from 'features/UserArea/DepositLimits';
import TransactionsHistory from 'features/UserArea/Transactions/TransactionsHistory';
import SelfExclusion from 'features/UserArea/SelfExclusion';
import Withdraw from 'features/UserArea/Withdraw';
import UserProfile from 'features/UserArea/UserProfile/index.old';
import Newsletter from 'features/UserArea/Newsletter';
import ActiveBets from 'features/History/ActiveBets';
import FinishedBets from 'features/History/FinishedBets';
import RetailBets from 'features/History/RetailBets';
import VoucherContainer from 'features/UserArea/Voucher';
import depositIcon from 'images/icons/deposit.png';
import withdrawIcon from 'images/icons/payout.png';
import historyIcon from 'images/icons/track.png';
import profileIcon from 'images/icons/profile.png';
import transactionsIcon from 'images/icons/transactions.png';
import selfExclusionIcon from 'images/icons/self-exclusion.png';
import depositLimitsIcon from 'images/icons/deposit-limit.png';
import newsletterIcon from 'images/icons/subscriptions.png';
import logoutIcon from 'images/icons/logout.png';
import voucherIcon from 'images/icons/voucher.png';
import getTexts from 'utils/localization';
import memoize from 'lodash/memoize';
const t = getTexts();

const ViewsMap = {
    indbetaling: Deposit,
    withdraw: Withdraw,
    activebets: ActiveBets,
    finishedbets: FinishedBets,
    retailbets: RetailBets,
    profile: UserProfile,
    transactions: TransactionsHistory,
    selfexclusion: SelfExclusion,
    depositlimits: DepositLimits,
    newsletter: Newsletter,
    voucher: VoucherContainer,
};

const Groups = [
    ['indbetaling', 'withdraw', 'voucher'],
    ['activebets', 'finishedbets', 'retailbets'],
    ['profile', 'transactions', 'selfexclusion', 'depositlimits', 'newsletter'],
    ['logout'],
];

const Paths = {
    menu: {
        url: 'konto',
        label: t.userArea.top.deposit,
        icon: depositIcon,
    },
    indbetaling: {
        url: 'konto/indbetaling',
        label: t.userArea.top.deposit,
        icon: depositIcon,
    },
    withdraw: {
        url: 'konto/withdraw',
        label: t.userArea.top.payout,
        icon: withdrawIcon,
    },
    voucher: {
        url: 'konto/voucher',
        label: t.userArea.nav.voucher,
        icon: voucherIcon,
        modalId: { mobile: 'VOUCHER' },
    },
    activebets: {
        url: 'konto/activebets',
        label: t.userArea.nav.activeBets,
        icon: historyIcon,
    },
    finishedbets: {
        url: 'konto/finishedbets',
        label: t.userArea.nav.finishedBets,
        icon: historyIcon,
    },
    retailbets: {
        url: 'konto/retailbets',
        label: t.userArea.nav.retailBets,
        icon: historyIcon,
    },
    profile: {
        url: 'konto/profile',
        label: t.userArea.nav.profile,
        icon: profileIcon,
        modalId: { mobile: 'PROFILE' },
    },
    transactions: {
        url: 'konto/kontohistorik',
        label: 'Kontohistorik',
        icon: transactionsIcon,
    },
    selfexclusion: {
        url: 'konto/selfexclusion',
        label: t.userArea.nav.selfExclusion,
        icon: selfExclusionIcon,
        modalId: { mobile: 'SELF_EXCLUSION' },
    },
    depositlimits: {
        url: 'konto/depositlimits',
        label: t.userArea.nav.depositLimits,
        icon: depositLimitsIcon,
        modalId: { mobile: 'DEPOSIT_LIMITS' },
    },
    newsletter: {
        url: 'konto/newsletter',
        label: t.userArea.nav.newsletter,
        icon: newsletterIcon,
        modalId: { mobile: 'NEWSLETTER' },
    },
    logout: {
        url: 'logout',
        label: t.userArea.nav.logOut,
        icon: logoutIcon,
    },
};

const getConfigByUrls = memoize(() => {
    return Object.fromEntries(Object.values(Paths).map(path => [path.url, path]));
});

const getComponentsByUrls = memoize(() => {
    return Object.fromEntries(Object.keys(Paths).map(key => [Paths[key].url, ViewsMap[key]]));
});

export { ViewsMap, Paths, Groups, getConfigByUrls, getComponentsByUrls };
